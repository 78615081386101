<template>
  <div class="container">
	<div v-show="showPage.main">
		<div class="user-nav">
			<el-form :inline="true" :model="queryParams" class="demo-form-inline">
				<el-col :span="20" style="height: 55px">
					<el-form-item>
					  <el-select
					    v-model="queryParams.termName"
					    placeholder="学期"
					    style="width: 188px"
					  >
					    <el-option v-for="item,index in constData.termList" :key="index" :label="item.termName" :value="item.termName"></el-option>
					  </el-select>
					</el-form-item>
					<el-form-item>
					  <el-select
					    v-model="queryParams.classId"
					    placeholder="班级"
					    style="width: 120px"
					  >
					    <el-option v-for="item,index in constData.clsList" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
					  </el-select>
					</el-form-item>
				</el-col>
				<el-col :span="4" style="height: 55px;text-align: right;">
					<el-form-item>
					  <el-button type="primary" @click="getMainList">搜索</el-button>
					</el-form-item>
				</el-col>
			</el-form>
		  <!-- <Search
		    :isSearch="false"
		    :isBtn="false"
		    :termlist="constData.termList"
		    :classlist="constData.clsList"
		    ref="search"
		    @handleChange="termChange"
		    @handleDeptChange="clsChange"
		  /> -->
		</div>
		<div class="main">
		  <el-row type="flex" justify="space-between">
		    <el-col :span="20">
		      <h3>心理健康教育教学计划</h3>
		    </el-col>
		    <el-col :span="4" class="title-action">
		      <el-button type="primary" @click="addNewPlan">添加</el-button>
		    </el-col>
		  </el-row>
		  <el-table
		    :data="mainList.list"
		    style="width: 100%; margin-top: 10px"
		    border
		    :header-cell-style="tableHeaderColor"
		    :cell-style="cellStyleFun"
		  >
		    <el-table-column type="index" label="序号" width="50"></el-table-column>
		    <el-table-column prop="planName" label="项目" width="250"></el-table-column>
			<el-table-column prop="className" label="班级" width="150"></el-table-column>
			<el-table-column prop="termName" label="学期" width="180"></el-table-column>
			<el-table-column prop="classNum" label="课时" width="50"></el-table-column>
			<el-table-column prop="classNumDone" label="已完成课时" width="130"></el-table-column>
			<el-table-column prop="classNumUnDone" label="未完成课时" width="130"></el-table-column>
			<el-table-column prop="createTime" label="计划创建时间" width="170"></el-table-column>
		    <el-table-column fixed="right" label="操作" width="220">
		      <template slot-scope="scope">
		        <el-button @click="clsRecManage(scope.row)" type="text"
		          >课程记录管理</el-button
		        >
		        <el-button @click="delItem(scope.row)" type="text" :disabled="scope.row.classNumDone > 0"
		          >删除</el-button
		        >
		      </template>
		    </el-table-column>
		  </el-table>
		
		  <pagination
		    v-show="mainList.total > 0"
		    :total="mainList.total"
		    :page.sync="queryParams.pageNum"
		    :limit.sync="queryParams.pageSize"
		    @pagination="getMainList"
		  />
		</div>
		
	</div>
	<div v-show="!showPage.main">
		<div class="user-nav">
			<el-button type="primary" @click="goBack">返回</el-button>    
		</div>
		<div class="main">
			<el-row :gutter="10">
				<el-col :span="4"><span>班级：{{ curDetail.className }}</span></el-col>
				<el-col :span="6"><span>学期：{{ curDetail.termName }}</span></el-col>
				<el-col :span="4"><span>计划课时：{{ curDetail.classNum }}</span></el-col>
			</el-row>
			<el-table
			  :data="curDetail._detailList"
			  style="width: 100%; margin-top: 10px"
			  border
			  :header-cell-style="tableHeaderColor"
			  :cell-style="cellStyleFun"
			>
			<el-table-column type="index" label="序号" width="50"></el-table-column>
			<el-table-column prop="_classNum" label="课时" width="100"></el-table-column>
			<el-table-column prop="classTitle" label="课题" width="250"></el-table-column>
			<el-table-column prop="teacherName" label="授课老师" width="150"></el-table-column>
			<el-table-column prop="uploadTime" label="授课时间" width="170"></el-table-column>
			<el-table-column prop="uploadStatus" label="课程记录状态" width="150"></el-table-column>
			<el-table-column fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button @click="clsDetail(scope.row)" type="text">详情</el-button>
					<el-button @click="uploadClsRec(scope.row)" type="text">上传课程记录</el-button>
				</template>
			</el-table-column>
			</el-table>
		</div>
	</div>
	<!-- 新增计划 -->
	<el-dialog title="心理健康教育教学计划" :visible.sync="dialog.add" width="400px" center>
		<el-form :model="addForm"
	            :rules="addRules"
	            ref="addForm">
			<el-form-item label="班级" prop="classId">
				<el-select
				  v-model="addForm.classId"
				  placeholder="班级"
				  style="width: 200px"
				>
				  <el-option v-for="item,index in constData.clsList" :key="index" :label="item.deptName" :value="item.deptId"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="课时" prop="classNum">
				<el-select
				  v-model="addForm.classNum"
				  placeholder="请选择课时"
				  style="width: 200px"
				>
				  <el-option v-for="i in 15" :key="i" :value="i"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="学期" prop="term">
				<span>{{ addForm.term }}</span>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialog.add = false">取消</el-button>
			<el-button type="primary" @click="confirmAddPlan">确认添加</el-button>
		</div>
	</el-dialog>
	<!-- 课程记录 - 仅查看 -->
	<el-dialog title="课程记录" :visible.sync="dialog.detail" width="600px" center>
		<el-form :model="curRec">
			<el-form-item label="课时：" prop="_classNum">
				<span>{{ curRec._classNum }}</span>
			</el-form-item>
			<el-form-item label="课题：" prop="classTitle">
				<span>{{ curRec.classTitle }}</span>
			</el-form-item>
			<el-form-item label="授课时间：" prop="uploadTime">
				<span>{{ curRec.uploadTime }}</span>
			</el-form-item>
			<el-form-item label="授课老师：" prop="teacherName">
				<span>{{ curRec.teacherName }}</span>
			</el-form-item>
			<el-form-item label="课程内容：" prop="classContent">
				<span>{{ curRec.classContent }}</span>
			</el-form-item>
			<el-form-item label="课堂照片：" prop="_fileUrl">
				<template v-for="(item, idx) in curRec._fileUrl" v-if="curRec._fileUrl.length > 0">
					<el-image :src="item" style="width: 100px; height: 100px" fit="fit" :preview-src-list="curRec._fileUrl" :key="idx"></el-image>
				</template>
				<span v-else></span>
			</el-form-item>
			<el-form-item label="教案：" prop="_lessonPlanUrl">
				<template v-for="(item, idx) in curRec._lessonPlanUrl" v-if="curRec._lessonPlanUrl.length > 0">
					<a
					:key="item"
					target="_blank"
					:download="'教案' + idx"
					:href="item">教案{{ idx + 1 }}</a>
				</template>
				<span v-else></span>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="closeDialog('detail')">确定</el-button>
		</div>
	</el-dialog>
	<!-- 课程记录 - 编辑 -->
	<el-dialog title="课程记录" :visible.sync="dialog.upload" width="600px" center>
		<el-form :model="uploadForm" ref="uploadForm" :rules="uploadRules">
			<el-form-item label="课时：" prop="_classNum">
				<span>{{ uploadForm._classNum }}</span>
			</el-form-item>
			<el-form-item label="课题：" prop="classTitle">
				<el-input v-model="uploadForm.classTitle" style="width: 250px;" placeholder="请输入课题"></el-input>
			</el-form-item>
			<el-form-item label="授课时间：" prop="uploadTime">
				<el-date-picker
				      v-model="uploadForm.uploadTime"
				      type="datetime"
				      placeholder="请选择日期时间"
							value-format="yyyy-MM-dd HH:mm:ss"
					   style="width: 250px;">
				    </el-date-picker>
			</el-form-item>
			<el-form-item label="授课老师：" prop="teacherName">
				<el-input v-model="uploadForm.teacherName"  style="width: 250px;" placeholder="请输入授课老师"></el-input>
			</el-form-item>
			<el-form-item label="课程内容：" prop="classContent">
				<el-input v-model="uploadForm.classContent" type="textarea" style="width: 250px;" placeholder="请输入课程内容"></el-input>
			</el-form-item>
			<el-form-item label="课堂照片：" prop="file">
				<el-upload
				  :action="upload.img.action"
				  :accept="upload.img.accept"
				  :limit="upload.img.limit"
				  :multiple="upload.multiple"
				  :file-list="upload.img.list"
				  :on-change="uploadChangeImg"
				  :on-remove="uploadDelImg"
				  :auto-upload="upload.auto"
				>
				  <i class="el-icon-plus" v-if="upload.img.list.length < upload.img.limit"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="教案上传：" prop="className">
				<el-upload
				  :action="upload.file.action"
				  :accept="upload.file.accept"
				  :limit="upload.file.limit"
				  :multiple="upload.multiple"
				  :file-list="upload.file.list"
				  :on-change="uploadChangeFile"
				  :on-remove="uploadDelFile"
				  :auto-upload="upload.auto"
				>
				  <i class="el-icon-plus" v-if="upload.file.list.length < upload.file.limit"></i>
				</el-upload>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="closeDialog('upload')">取消</el-button>
			<el-button type="primary" @click="confirmUpload">确定</el-button>
		</div>
	</el-dialog>
  </div>
</template>

<script>
import { getTerm } from "@/api/public/search"
import {
  updatePsyHealthPlan,
  getPsyHealthPlan,
  delPsyHealthPlan,
  psyHealthPlanDetail,
  psyHealthPlanRecord,
  psyHealthPlanCls2
} from "@/api/teacher/manager";
export default {
  data() {
    return {
		imgSrc: this.imageSrc(),
		showPage: {
			main: true,
		},
		constData: {
			termList: [],
			clsList: []
		},
		queryParams: {
			classId: "",
			termName: "",
			pageNum: 1,
			pageSize: 15
		},
		mainList: {
			total: 0,
			list: [],
			loading: false,
		},
		addForm: {
		  classId: '',
		  classNum: '',
		  className: '',
		  term: ''
		},
		upload: {
			multiple: false,
			auto: false,
			img: {
				action: '',
				accept: 'image/*',
				limit: 6,
				size: 1024 * 1024 * 5,
				list: []
			},
			file: {
				action: '',
				accept: '.doc,.docx,.xls,.xlsx,.pdf',
				limit: 3,
				list: []
			}
		},
		uploadForm: {
			id: '',
			planId: '',
			classNum: '',
			classTitle: '',
			uploadTime: '',
			teacherName: '',
			classContent: '',
			file: '',
			file2: ''
		},
		curDetail: {
			className: '',
			termName: '',
			classNum: '',
			_detailList: []
		},
		curRec: {
			_classNum: '',
			classTitle: '',
			uploadTime: '',
			teacherName: '',
			classContent: '',
			_fileUrl: [],
			_lessonPlanUrl: []
		},
		dialog: {
			add: false,
			upload: false,
			detail: false
		},
		addRules: {
			classId: [{ required: true, message: "请选择班级", trigger: "blur" }],
			classNum: [{ required: true, message: "请选择课时", trigger: "blur" }]
		},
		uploadRules: {
		  classTitle: [{ required: true, message: "请输入课题", trigger: "blur" }],
		  uploadTime: [{ required: true, message: "请选择授课时间", trigger: "blur" }],
		  teacherName: [{ required: true, message: "请输入授课老师", trigger: "blur" }],
		  classContent: [{ required: true, message: "请输入课程内容", trigger: "blur" }]
		}
    }
  },
  async created() {
	await this.getTerm()
	await this.getCls()
	await this.getMainList()
  },
  methods: {
	// 获取学期
	getTerm() {
		getTerm({}).then(res => {
			this.constData.termList = res.data
			this.queryParams.termName = res.data[0].termName
		})
	},
	// 管理班级
	getCls() {
		psyHealthPlanCls2({}).then(res => {
			this.constData.clsList = res.data
			this.queryParams.classId = res.data[0].deptId
		})
	},
	// 主列表
    getMainList() {
      getPsyHealthPlan(this.queryParams).then((res) => {
        this.mainList.list = res.data.list;
        this.mainList.total = res.data.size;
      });
    },
    // 查看详情
    clsRecManage(row) {
		let d = JSON.parse(JSON.stringify(row))
		this.curDetail.className = d.className
		this.curDetail.termName = d.termName
		this.curDetail.classNum = d.classNum
		this.curDetail.id = d.id
		this.getDetail()
    },
	// 请求详情
	getDetail() {
		psyHealthPlanDetail({
			planId: this.curDetail.id
		}).then((res) => {
			res.data.forEach(v => {
				v._classNum = `课时${v.classNum}`
			})
			this.curDetail._detailList = res.data
			this.showPage.main = false
		});
	},
	// 新增
	addNewPlan() {
		this.addForm.term = this.queryParams.termName
		this.dialog.add = true
	},
	// 确认新增
	confirmAddPlan() {
		this.addForm.className = this.constData.clsList.filter(v => v.deptId == this.addForm.classId)[0].deptName
		this.$refs['addForm'].validate(v => {
			if (v) {
				updatePsyHealthPlan(this.addForm).then(res => {
					this.$message.success('新增计划成功')
					this.closeDialog('add')
					this.getMainList()
				})
			} else {
				return false;
			}
		})
	},
	// 关闭弹窗
	closeDialog(nm) {
		this.dialog[nm] = false
	},
	// 返回列表
	goBack() {
		this.showPage.main = true
		this.getMainList()
	},
    // 删除
    delItem(row) {
		delPsyHealthPlan({
			id: row.id
		}).then(res => {
			this.$message.success('删除计划成功')
			this.getMainList()
		})
    },
	// 课时详情
	clsDetail(row) {
		let d = JSON.parse(JSON.stringify(row))
		this.curRec._classNum = d._classNum
		this.curRec.classTitle = d.classTitle
		this.curRec.uploadTime = d.uploadTime
		this.curRec.teacherName = d.teacherName
		this.curRec.classContent = d.classContent
		this.curRec._fileUrl = []
		this.curRec._lessonPlanUrl = []
		if (d.fileUrl) {
			let temp = d.fileUrl.split(',')
			temp.forEach(v => {
				this.curRec._fileUrl.push(this.imgSrc + v)
			})
		}
		if (d.lessonPlanUrl) {
			let temp = d.lessonPlanUrl.split(',')
			temp.forEach(v => {
				this.curRec._lessonPlanUrl.push(this.imgSrc + v)
			})
		}
		this.dialog.detail = true
	},
	// 上传课程记录
	uploadClsRec(row) {
		let d = JSON.parse(JSON.stringify(row))
		this.uploadForm = Object.assign({}, d)
		this.uploadForm._classNum = `课时${row.classNum}`
		this.uploadForm.uploadTime = d.uploadTime
		this.upload.img.list = []
		this.upload.file.list = []
		this.dialog.upload = true
	},
	// 确认上传
	confirmUpload() {
		this.$refs['uploadForm'].validate(valid => {
			if (valid) {
				let fd = new FormData()
				fd.append('id', this.uploadForm.id)
				fd.append('planId', this.uploadForm.planId)
				fd.append('classNum', this.uploadForm.classNum)
				fd.append('classTitle', this.uploadForm.classTitle)
				fd.append('uploadTime', this.uploadForm.uploadTime)
				fd.append('teacherName', this.uploadForm.teacherName)
				fd.append('classContent', this.uploadForm.classContent)
				this.upload.img.list.forEach(v => {
					fd.append('file', v)
				})
				this.upload.file.list.forEach(v => {
					fd.append('file2', v)
				})
				psyHealthPlanRecord(fd).then(res => {
					this.$message.success("上传成功")
					this.closeDialog('upload')
					this.getDetail()
				})
			}
		})
	},
	// 上传图片
	uploadChangeImg(file, fileList) {
		let rawTp = file.raw.type
		// 图片
		let allowTp = ['image/gif', 'image/png', 'image/jpeg']
		if (allowTp.indexOf(rawTp) == -1) {
			this.$message.error("请上传图片格式！")
			fileList.pop()
		} else {
			if (file.size > this.upload.img.size) {
				this.$message.error(`图片体积不得大于${this.upload.img.size / 1024 / 1024}MB！`)
				fileList.pop()
			} else {
				this.upload.img.list.push(file.raw)
				this.$message.success("上传成功")
			}
		}
	},
	// 图片删除
	uploadDelImg(file, fileList) {
		this.upload.img.list = fileList
	},
	// 上传教案
	uploadChangeFile(file, fileList) {
		let rawTp = file.raw.type
		// pdf, excel表格, word文档
		let allowTp = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
		if (allowTp.indexOf(rawTp) == -1) {
			this.$message.error("教案仅支持pdf、excel表格、word格式！")
			fileList.pop()
		} else {
			this.upload.file.list.push(file.raw)
			this.$message.success("上传成功")
		}
	},
	// 教案删除
	uploadDelFile(file, fileList) {
		this.upload.file.list = fileList
	},
	// 表头样式
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
	// 单元格样式
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style scoped>
.user-nav {
  padding: 10px 12px;
  background: #fff;
  border: 1px solid rgba(153, 153, 153, 0.15);
  height: 63px;
  box-sizing: border-box;
}
.main {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px 20px;
}
.main h3 {
  font-size: 16px;
  line-height: 32px;
  color: #616266;
  margin: 0;
}
.main .title-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

</style>
